<template>
  <c-box>
    <Tabs
      active-tab="recommendation"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="tabs"
    >
      <c-box
        v-if="isProgramConsultationBasic"
        margin-bottom="16px"
      >
        <Info 
          variant="warning"
          text="Formulir tidak dapat diubah dan ditambah karena klien menjalankan program Konsultasi Basic"
          padding="16px 28px"
          font-size="14px"
        />
      </c-box>
      <c-flex
        align-items="center"
        justify-content="space-between"
        margin-bottom="12px"
      >
        <c-flex
          align-items="center"
          gap="16px"
        >
          <c-button
            variant="ghost"
            width="30px"
            min-width="30px"
            height="30px"
            margin="0"
            padding="0"
            @click="$router.push({
              ...$route,
              name: isRoleNutritionist ? 'nutri.meal-plan.meal-plan-schedule.detail' : 'admin.clients.meal-plan.meal-plan-schedule',
            })
            "
          >
            <c-box
              :width="['12px', '15px']"
              :height="['12px', '15px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-arrow-down.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
                style="transform: rotate(90deg);"
              />
            </c-box>
          </c-button>
          <c-text
            color="neutral.superDarkGray"
            font-size="28px"
            font-weight="700"
          >
            Rekomendasi Menu
          </c-text>
        </c-flex>
        
        <BaseButton
          v-if="isRoleNutritionist"
          :left-svg-icon="preview ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          :is-disabled="isLoadingData || !isEdited || isProgramConsultationBasic"
          @click="isEdited && !isProgramConsultationBasic ? $router.push({
            ...$route,
            name: 'nutri.meal-plan.menu-recommendation.edit',
            query: {
              day: 1,
            },
          }) : ''"
        >
          {{
            preview
              ? 'Edit'
              : 'Tambah'
          }}
        </BaseButton>
      </c-flex>
      <PreviewMenuRecommendation
        :calendars="calendars"
        :data="preview"
        :is-loading="isLoadingData"
      />
    </Tabs>
  </c-box>
</template>

<script>
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import PreviewMenuRecommendation from '@/components/meal-plan/_widgets/preview-menu-recommendation.vue'
import Info from '@/components/info.vue'

export default {
  name: 'MealPlanMenuRecommendationContainer',
  components: { 
    Tabs,
    BaseButton,
    PreviewMenuRecommendation,
    Info,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: [Object, null],
      default: null,
    },
    calendars: {
      type: Array,
      default: () => [],
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    isAllFormValid,
  },
}
</script>
