import { render, staticRenderFns } from "./preview-menu-recommendation.vue?vue&type=template&id=bb5f4b7c&"
import script from "./preview-menu-recommendation.vue?vue&type=script&lang=js&"
export * from "./preview-menu-recommendation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CSpinner: require('@chakra-ui/vue').CSpinner, CText: require('@chakra-ui/vue').CText, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CImage: require('@chakra-ui/vue').CImage, CGrid: require('@chakra-ui/vue').CGrid})
